<template>
  <div class="elv-landing-pricing-container">
    <div class="elv-landing-pricing-header">
      <h3>{{ t('report.welcomeUser', { userName: user?.name }) }}</h3>
      <template v-if="!globalStore.projectList.length">
        <h3>{{ t('report.startCreateProject') }}</h3>
      </template>
    </div>
    <div class="elv-landing-pricing-content">
      <div>
        <p>{{ t('report.choosePlan') }}</p>
        <div class="elv-landing-pricing-plan-button">
          <span :class="{ 'is-selected': planTime === 'month' }" @click="onChangePlanTime('month')">Monthly</span
          ><span :class="{ 'is-selected': planTime === 'annual' }" @click="onChangePlanTime('annual')">Annually</span>
        </div>
      </div>
      <ul class="elv-landing-pricing-plan">
        <li v-for="(item, index) in planConfig" :key="index" class="elv-landing-pricing-plan-item">
          <div class="elv-landing-pricing-plan-item__header">
            <h4>{{ item.plan }}</h4>
            <p v-if="index === 3">{{ t('common.customPricing') }}</p>
            <p v-else>
              ${{ planTime === 'month' ? item.price : item.annuallyPrice }} <span>/ {{ item.priceUnit }}</span>
            </p>
          </div>
          <ol>
            <template v-if="index !== 3">
              <li>
                <p>{{ t('report.Transactions') }}</p>
                <span>{{ item.transactions }}</span>
              </li>
              <li>
                <p>{{ t('project.nav.sources') }}</p>
                <span>{{ item.sources }}</span>
              </li>
              <li>
                <p>{{ t('common.entities') }}</p>
                <span>{{ item.entity }}</span>
              </li>
              <li>
                <p>{{ t('common.users') }}</p>
                <span>{{ item.users }}</span>
              </li>
            </template>
            <template v-else>
              <li>
                <span>{{ t('common.unlimited') }}</span>
                <p>{{ t('common.transactionsEntitiesUsers') }}</p>
              </li>
              <li>
                <p class="elv-landing-pricing-plan-enterprise__info">
                  {{ t('common.enterprisePlanInfo') }}
                </p>
              </li>
            </template>
          </ol>

          <div class="elv-landing-pricing-plan-item__contact">
            <elv-button v-if="index === 0" width="140" height="44" round @click="onApplyNow">{{
              t('button.getStarted')
            }}</elv-button>
            <a v-else href="https://www.elven.com/contacts" target="_blank">{{ t('button.talkToSales') }}</a>
          </div>
        </li>
      </ul>
    </div>

    <div class="elv-landing-pricing-help">
      <p class="elv-landing-pricing-help-title">{{ t('report.needHelp') }}</p>
      <div class="elv-landing-pricing-help-content">
        <div class="elv-landing-pricing-help-contact" @click="openJumpCalendar">
          <div class="elv-landing-pricing-help-header__icon">
            <img src="@/assets/img/landing-price-contact.png" alt="contact" />
            <SvgIcon
              name="arrow-right-line"
              width="24"
              height="24"
              class="elv-landing-pricing-help-header__icon-arrow"
            />
          </div>
          <p class="elv-landing-pricing-help-title__text">{{ t('report.bookDemoTitle') }}</p>
          <p class="elv-landing-pricing-help-info__text">
            {{ t('report.bookDemoInfo') }}
          </p>
        </div>
        <div class="elv-landing-pricing-help-docs" @click="openJumpDocs">
          <div class="elv-landing-pricing-help-header__icon">
            <div>
              <SvgIcon name="book-open-line" width="24" height="24" />
            </div>
            <SvgIcon
              name="arrow-right-line"
              width="24"
              height="24"
              class="elv-landing-pricing-help-header__icon-arrow"
            />
          </div>
          <p class="elv-landing-pricing-help-title__text">{{ t('report.ReadDocsTitle') }}</p>
          <p class="elv-landing-pricing-help-info__text">
            {{ t('report.ReadDocsInfo') }}
          </p>
        </div>
      </div>
    </div>
    <img src="@/assets/img/landing-price-bg.png" alt="" class="elv-landing-pricing-bg" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useGlobalStore } from '@/stores/modules/global'
import { useUserGlobalStore } from '@/stores/modules/global/user'

const { t } = useI18n()
const router = useRouter()
// eslint-disable-next-line no-unused-vars
const globalStore = useGlobalStore()
const userGlobalStore = useUserGlobalStore()
const { user } = storeToRefs(userGlobalStore)

const planTime = ref('month')

const planConfig = reactive([
  {
    plan: t('common.free'),
    price: 0,
    annuallyPrice: 0,
    priceUnit: t('common.month'),
    transactions: '30,000',
    sources: 30,
    entity: 1,
    users: 3
  },
  {
    plan: t('common.basic'),
    price: 129,
    annuallyPrice: 116.6,
    priceUnit: t('common.month'),
    transactions: '30,000',
    sources: 30,
    entity: 1,
    users: 3
  },
  {
    plan: t('common.pro'),
    price: 249,
    annuallyPrice: 216.6,
    priceUnit: t('common.month'),
    transactions: '300,000',
    sources: 100,
    entity: 5,
    users: 6
  },
  {
    plan: t('common.enterprise'),
    price: 249,
    annuallyPrice: 249,
    priceUnit: t('common.month'),
    transactions: t('common.unlimited'),
    sources: t('common.unlimited'),
    entity: t('common.unlimited'),
    users: t('common.unlimited')
  }
])

const onApplyNow = () => {
  router.push({ name: 'landing-apply' })
}

const openJumpCalendar = () => {
  window.open('https://elvencom.feishu.cn/scheduler/d4b862ba3c9a4d2d', '_target')
}
const openJumpDocs = () => {
  window.open('https://docs.elven.com', '_target')
}

const onChangePlanTime = (time: string) => {
  planTime.value = time
}
</script>

<style lang="scss" scoped>
.elv-landing-pricing-container {
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7.5vh;
  position: relative;

  .elv-landing-pricing-header {
    display: flex;
    flex-direction: column;
    width: 869px;

    h3 {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      color: #0e0f11;

      &:nth-child(2) {
        margin-top: 13px;
      }
    }

    > div {
      position: relative;
      margin-top: 25px;

      img {
        display: block;
        width: 572px;
        height: 110px;
      }
    }

    .elv-button {
      position: absolute;
      right: 28px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .elv-landing-pricing-content {
    margin-top: 18px;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > p {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 29px;
        color: #838d95;
        margin-bottom: 14px;
      }

      > div {
        width: 135px;
        height: 29px;
        border-radius: 30px;
        border: 1px solid #dde1e6;
        display: flex;
        align-items: center;

        span {
          width: 68px;
          height: 29px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Commissioner';
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          background-color: #f9fafb;
          cursor: pointer;

          &:last-child {
            border-radius: 0px 49px 49px 0px;
            border-left: 1px solid #dde1e6;
          }

          &:first-child {
            border-radius: 49px 0px 0px 49px;
          }

          &.is-selected {
            color: #0e0f11;
            font-weight: 500;
            background-color: #fff;
          }
        }
      }
    }

    .elv-landing-pricing-plan {
      display: flex;
      border-radius: 4px;
      border: 1px solid #dde1e6;
      background-color: #fff;

      .elv-landing-pricing-plan-item {
        width: 216px;
        height: 387px;
        border-right: 1px solid #dde1e6;

        &:last-child {
          border-right: none;
        }

        &:nth-child(1) .elv-landing-pricing-plan-item__header h4 {
          leading-trim: both;
          text-edge: cap;
          letter-spacing: -0.03em;
          background: linear-gradient(95.91deg, #00d32f -2.64%, #05a31f 27.09%, #c4eccc 105.06%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          // text-fill-color: transparent;
        }

        &:nth-child(2) .elv-landing-pricing-plan-item__header h4 {
          leading-trim: both;
          text-edge: cap;
          letter-spacing: -0.03em;
          background: linear-gradient(95.91deg, #d3a500 -2.64%, #ff730e 27.09%, #ffb88b 105.06%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          // text-fill-color: transparent;
        }

        &:nth-child(3) .elv-landing-pricing-plan-item__header h4 {
          leading-trim: both;
          text-edge: cap;
          letter-spacing: -0.03em;
          background: linear-gradient(101.06deg, #1351f0 -1.56%, #1a47e8 28.16%, #0085ff 106.1%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          // text-fill-color: transparent;
        }

        &:nth-child(4) .elv-landing-pricing-plan-item__header h4 {
          leading-trim: both;
          text-edge: cap;
          letter-spacing: -0.03em;
          background: linear-gradient(101.06deg, #f02013 -1.56%, #e81a1a 28.16%, #ff774b 106.1%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          // text-fill-color: transparent;
        }
      }

      .elv-landing-pricing-plan-item__header {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 71px;
        background: #f9fafb;
        border-bottom: 1px solid #dde1e6;

        h4 {
          margin-bottom: 5px;
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 140%;
          flex: none;
          order: 0;
          flex-grow: 0;
        }

        p {
          font-family: 'Commissioner';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #000000;

          span {
            margin-left: 4px;
            font-size: 14px;
            color: #636b75;
          }
        }
      }

      ol {
        width: 100%;
        height: 240px;
        padding: 16px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1px solid #dde1e6;

        li {
          position: relative;
          margin-bottom: 8px;
          padding-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;

            &::after {
              display: none;
            }
          }

          &::after {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            width: 51px;
            height: 1px;
            background: #dde1e6;
          }

          p {
            font-family: 'Commissioner';
            text-align: center;
            font-weight: 500;
            font-size: 12px;
            line-height: 17px;
            color: #636b75;
            margin-bottom: 1px;
          }

          .elv-landing-pricing-plan-enterprise__info {
            padding: 0 20px;
            text-align: inherit;
            font-weight: 400;
          }

          span {
            display: block;
            text-align: center;
            font-family: 'Commissioner';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
          }
        }
      }

      .elv-landing-pricing-plan-item__contact {
        width: 100%;
        height: 76px;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          font-family: 'Commissioner';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-decoration-line: underline;
          color: #1753eb;
        }
      }
    }
  }

  .elv-landing-pricing-help {
    margin-top: 25px;
    width: 869px;

    .elv-landing-pricing-help-title {
      color: #838d95;
      font-size: 14px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 7px;
    }

    .elv-landing-pricing-help-content {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .elv-landing-pricing-help-contact,
    .elv-landing-pricing-help-docs {
      width: 420px;
      height: 160px;
      box-sizing: border-box;
      padding: 20px 18px 18px 20px;
      border-radius: 5px;
      border: 1px solid #ede9fd;
      background: #fbfaff;
      cursor: pointer;

      .elv-landing-pricing-help-title__text {
        color: #0e0f11;
        font-size: 16px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 4px;
      }

      .elv-landing-pricing-help-info__text {
        color: #636b75;
        font-size: 12px;
        font-weight: 400;
        font-family: 'Plus Jakarta Sans';
        line-height: 16px;
      }
    }

    .elv-landing-pricing-help-docs {
      .elv-landing-pricing-help-header__icon {
        margin-bottom: 9px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
          width: 48px;
          height: 48px;
          border-radius: 48px;
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .elv-report-guide-step-help-header__icon-arrow {
          fill: #1343bf;
        }
      }
    }

    .elv-landing-pricing-help-contact {
      border: 1px solid #e6ece9;
      background: #f6fffa;

      .elv-landing-pricing-help-header__icon {
        margin-bottom: 9px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 130px;
          height: 48px;
          display: block;
        }
      }
    }
  }

  .elv-landing-pricing-bg {
    position: absolute;
    bottom: 0;
    right: -20px;
    width: 1115px;
    height: 435px;
    z-index: -1;
  }
}
</style>
